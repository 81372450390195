import cloneDeep from 'lodash/cloneDeep';

import v110Schema from 'src/components/forms/schemas/eventResult/inquiryFormDetails/1.1.0';
import { Patient_InsuranceEligibilityCheckResult as PatientInsuranceEligibilityCheckResult } from 'src/generated/gql/graphql';

const v120Schema = cloneDeep(v110Schema);
delete v120Schema.properties;

export default {
  ...v120Schema,
  properties: {
    ...v110Schema.properties,
    isWaitlisted: {
      type: 'boolean',
    },
    insuranceEligibility: {
      type: 'string',
      renderer: {
        component: ({ model }) => {
          return model.insuranceEligibility === PatientInsuranceEligibilityCheckResult.Active
            ? 'Active'
            : 'Unable to Verify';
        },
      },
    },
    insuranceMemberId: {
      type: 'string',
    },
    DOB: {
      type: 'string',
    },
  },
};
