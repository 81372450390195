import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import ClearableSelect from 'src/components/forms/controls/clearableSelect';
import ProviderReference from 'src/components/forms/controls/providerReference';
import FormikEffect from 'src/components/forms/effect';
import Field from 'src/components/forms/field';
import { ENROLLMENT_STATUS_OPTIONS } from 'src/components/forms/schemas/definitions';
import Accordion from 'src/components/pages/pageElements/accordion';
import FilterSummary from 'src/components/pages/pageElements/filterSummary';

export default function PatientsListFilters({ queryParams, handleFiltersChange, rootStore }) {
  const classes = usePatientsListFiltersStyles();
  // Filter values keep track of what we last applied in the search request
  const [filterValues, setFilterValues] = useState({});
  // Form values keep track of what we're currently selecting in the UI, so that
  // we may kick off another search request when we're ready
  const [localValues, setLocalValues] = useState({});

  // Show/hide condition for the convenient "filter" button that appears only
  // when one of the "x" remove buttons in the summary are used.
  const [showQuickFilter, setShowQuickFilter] = useState(false);

  useEffect(() => {
    (async () => {
      const values = {};

      await Promise.all(
        Object.keys(careTeamFilterNameMap).map(async filterKey => {
          const filterValue = queryParams[filterKey];
          if (filterValue) {
            values[filterKey] =
              filterValue !== 'none'
                ? await rootStore.getProviderById(filterValue)
                : emptyProviderOption(emptyProviderOptionLabel(careTeamFilterNameMap[filterKey]));
          } else {
            // Intentionally set to null so that select filters can update themselves
            values[filterKey] = null;
          }
        }),
      );

      // eslint-disable-next-line camelcase

      values.enrollmentStatus_status = queryParams.enrollmentStatus_status
        ? enrollmentStatusOptionForValue(queryParams.enrollmentStatus_status)
        : null;

      setFilterValues(values);
      setLocalValues(values);
    })();
  }, [
    // Initial filter values are driven by the query param state
    queryParams,
    // rootStore needs to be mentioned so that possible changes to getProviderById are accounted for.
    // Deeper changes like receiving a chat message will not trigger the Effect.
    rootStore,
  ]);

  const validationSchema = Yup.object().shape({
    coordinator: Yup.object().nullable(),
    peer: Yup.object().nullable(),
    prescriber: Yup.object().nullable(),
    enrollmentStatus_status: Yup.object().nullable(),
  });

  const filterFields = [
    ...Object.entries(careTeamFilterNameMap).map(([filterKey, filterName]) =>
      getCareTeamFilterField(filterName, filterKey),
    ),
    getEnrollmentStatusFilterField('Enrollment Status', 'enrollmentStatus_status'),
  ];

  // Object used to clear all values later by intentionally mapping every
  // filter field to null
  const clearedFiltersValue = mapValues(keyBy(filterFields, 'key'), () => null);

  const hasUnappliedChanges = filterFields.some(filter => {
    const appliedValue = filterValues[filter.key];
    const unappliedValue = localValues[filter.key];
    const match =
      // Either both filters match,
      isEqual(appliedValue, unappliedValue) ||
      // Or neither filter is currently selected
      (isNil(appliedValue) && isNil(unappliedValue));
    return !match;
  });

  function resetLocalValues() {
    setLocalValues(filterValues);
    setShowQuickFilter(false);
  }

  function applyUpdatedValues(updatedValues) {
    handleFiltersChange(updatedValues);
    setShowQuickFilter(false);
  }

  function clearAllLocalValues() {
    setLocalValues(clearedFiltersValue);
    if (hasUnappliedChanges) {
      setShowQuickFilter(hasUnappliedChanges);
    }
  }

  function removeLocalFilter(filterKey) {
    setLocalValues({
      ...localValues,
      [filterKey]: null,
    });
    setShowQuickFilter(true);
  }

  return (
    <FormControl fullWidth className={classes.accordionContainer}>
      <div className={classes.formLabel}>
        <InputLabel shrink>Filtered By</InputLabel>
      </div>
      <Accordion
        className={classes.accordion}
        additionalButtons={[
          {
            label: 'Clear All',
            onClick: clearAllLocalValues,
          },
        ]}
        submitLabel="Filter"
        summary={
          <FilterSummary
            filterFields={filterFields}
            localValues={localValues}
            hasUnappliedChanges={hasUnappliedChanges}
            removeLocalFilter={removeLocalFilter}
            onFilter={applyUpdatedValues}
            setShowQuickFilter={setShowQuickFilter}
            showQuickFilter={showQuickFilter}
          />
        }
        onCancel={resetLocalValues}
        onSubmit={() => applyUpdatedValues(localValues)}
      >
        <Formik validationSchema={validationSchema} initialValues={localValues} enableReinitialize>
          {() => (
            <div className={classes.formContainer}>
              <FormikEffect
                onChange={(current, prev) => {
                  if (current.values !== prev.values) {
                    setLocalValues(current.values);
                  }
                }}
              />

              {filterFields.map(filterField => (
                <Field
                  key={filterField.key}
                  component={filterField.component}
                  {...filterField.props}
                />
              ))}
            </div>
          )}
        </Formik>
      </Accordion>
    </FormControl>
  );

  function getCareTeamFilterField(label, key) {
    return {
      key,
      props: {
        isClearable: true,
        loadOptions: q => getProviderSelectOptions(label, q),
        label,
        name: key,
        placeholder: 'Select...',
      },
      component: ProviderReference,
    };
  }

  function getEnrollmentStatusFilterField(label, key) {
    const enrollmentStatusOptionsArray = Object.keys(ENROLLMENT_STATUS_OPTIONS).map(
      enrollmentStatusOptionForValue,
    );

    return {
      key,
      props: {
        isClearable: true,
        options: enrollmentStatusOptionsArray,
        label,
        name: key,
        placeholder: 'Select...',
      },
      component: ClearableSelect,
    };
  }

  /**
   * Autocomplete search function to insert "No ___ assigned"
   */
  async function getProviderSelectOptions(label, q) {
    const { searchProviders } = rootStore;

    const providers = await searchProviders(q);

    const emptyOptionString = emptyProviderOptionLabel(label);
    if (!q || emptyOptionString.includes(q)) {
      return [emptyProviderOption(emptyOptionString), ...providers];
    } else {
      return providers;
    }
  }
}

const usePatientsListFiltersStyles = makeStyles({
  accordionContainer: {
    // Required to make input label appear
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    '& > *': {
      display: 'inline-flex',
      marginBottom: 30,
      marginRight: 30,
      verticalAlign: 'inherit',
      width: 300,
    },
  },
  formLabel: {
    height: 20,
  },
  careTeamLabel: {
    fontStyle: 'italic',
  },
  wordWrapped: {
    minWidth: 100,
  },
});

function emptyProviderOption(label) {
  return {
    firstName: label,
    lastName: '',
    id: 'none',
  };
}

function emptyProviderOptionLabel(label) {
  return `No ${label} Assigned`;
}

function enrollmentStatusOptionForValue(optionKey) {
  return { label: ENROLLMENT_STATUS_OPTIONS[optionKey], value: optionKey };
}

const careTeamFilterNameMap = {
  prescriber: 'Clinician',
  coordinator: 'Care Advocate',
  peer: 'Peer Recovery Specialist',
  caseManager: 'Case Manager',
};
