import { useMemo } from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { EVENT_LOCATION_VERIFICATION } from 'src/events/hooks/useEventLocationVerification.gql';

interface EventLocationVerificationResult {
  actualState: string;
  expectedState: string;
}

interface EventLocationVerification {
  isVerified: boolean;
  verifiedLocation: string;
}

export const useEventLocationVerification = (
  eventId?: string | null,
): EventLocationVerification | undefined => {
  const { data } = useTypedSWR(eventId ? [EVENT_LOCATION_VERIFICATION, { eventId }] : null);

  const { actualState, expectedState } =
    (data?.staff_getLocationVerificationForEvent as EventLocationVerificationResult) || {};

  const isVerified = useMemo(() => {
    if (!eventId) return false;
    if (!actualState || !expectedState) return false;
    return actualState === expectedState;
  }, [actualState, expectedState, eventId]);

  if (!eventId) return undefined;

  return { isVerified, verifiedLocation: actualState };
};
