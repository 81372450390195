import Typography from '@material-ui/core/Typography';
import { differenceInYears, format, parseISO } from 'date-fns';
import React from 'react';

import { address, provider } from 'src/components/forms/schemas/definitions';
import PhoneNumber from 'src/components/general/phoneNumber';
import { TIMEZONES, TIMEZONE_LABELS } from 'src/util/timezones';

export const latest = {
  type: 'object',
  title: 'Create Patient',
  properties: {
    firstName: {
      type: 'string',
      hideRendering: true,
    },
    lastName: {
      type: 'string',
      hideRendering: true,
    },
    email: {
      type: 'string',
      format: 'email',
      renderer: {
        component: ({ model }) => (
          <>
            <>{model.email}</>
            <Typography variant="body2">
              Email <strong>may not</strong> be used to communicate with participants.
            </Typography>
          </>
        ),
      },
    },
    phone: {
      type: 'string',
      format: 'phone',
      renderer: {
        component: ({ model }) => (
          <>
            <PhoneNumber number={model.phone} />
            {model.phonePrivate && ' (Private)'}
          </>
        ),
      },
    },
    timezone: {
      type: 'string',
      options: TIMEZONES,
      renderer: {
        component: ({ model }) => TIMEZONE_LABELS[model.timezone],
      },
    },
    dob: {
      type: 'string',
      title: 'Birthday',
      format: 'date',
      renderer: {
        component: ({ model }) => {
          // TODO: Verify if this is even being used anywhere?
          // @see {@link https://github.com/bouldercare/boulder/pull/5220#discussion_r1044883888}
          return (
            model.dob && (
              <>
                {`${format(parseISO(model.dob), 'PP')} (${format(
                  parseISO(model.dob),
                  'MM/dd/yyyy',
                )}) - Age${differenceInYears(new Date(), parseISO(model.dob))}`}
              </>
            )
          );
        },
      },
    },
    prescriber: Object.assign({}, provider, {
      title: 'Prescribing Clinician',
    }),
    coordinator: Object.assign({}, provider, {
      title: 'Care Advocate',
    }),
    peer: Object.assign({}, provider, {
      title: 'Peer Recovery Specialist',
    }),
    homeAddress: address,
    mailingAddress: Object.assign({}, address, {
      title: 'Mailing Address (if different)',
    }),
  },
  required: ['firstName', 'lastName'],
};
