import { gql } from 'src/generated/gql';

export const EVENT_LOCATION_VERIFICATION = gql(`
    query EventLocationVerification($eventId: ID!) {
        staff_getLocationVerificationForEvent(eventId: $eventId) {
            expectedState
            actualState
        }
    }
`);
