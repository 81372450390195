import React from 'react';

import { useEventLocationVerification } from 'src/events/hooks/useEventLocationVerification';
import { useStyles as usePreVisitDisplayStyles } from 'src/events/styles/preVisitDisplay.styles';

export const EventLocationVerification = ({ eventId }: { eventId: string | null }) => {
  const preVisitDisplayStyles = usePreVisitDisplayStyles();

  const { isVerified, verifiedLocation } = useEventLocationVerification(eventId) || {};

  if (!eventId || !isVerified) return null;

  return (
    <div className={preVisitDisplayStyles.sectionContainer} data-testid="location-verification">
      <div className={preVisitDisplayStyles.sectionLabel}>PATIENT LOCATION</div>
      <div>Location in {verifiedLocation} is verified by patient device</div>
    </div>
  );
};
